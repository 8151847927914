import { faEye, faUserLock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Col, Form, Input, Modal, notification, Row, Table } from 'antd'
import { navigate } from 'gatsby'
import React, { useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import client from '../../../api/client'
import { AddUserModal } from '../../../components/admin'
const { Search } = Input
const tableTitleStyle = {
  display: 'flex',
  alignItems: 'center',
  fontSize: 24,
}
const subMenus = [
  {
    name: 'Илгээгчдийн жагсаалт',
    dataIndex: 'senders',
  },
  {
    name: 'Хүлээн авагчдын жагсаалт',
    dataIndex: 'receivers',
  },
  {
    name: 'Ажилчдын жагсаалт',
    dataIndex: 'employees',
  },
]
const Users = () => {
  const [userDetailsFormRef] = Form.useForm()
  const [changePinFormRef] = Form.useForm()
  const [activeSubMenu, setActiveSubMenu] = useState('senders')
  const [tableLoading, setTableLoading] = useState(true)
  const [addUserModalVisible, setAddUserModalVisible] = useState(false)
  const [changePinModalVisible, setChangePinModalVisible] = useState(false)
  const [changeUserId, setChangeUserId] = useState(null)
  const [isEditting, setIsEditting] = useState(false)
  const [userDetails, setUserDetails] = useState({})
  const [apiLoading, setApiLoading] = useState(false)
  const [sendersData, setSendersData] = useState({
    tableData: [],
    dataCount: 0,
  })
  const [receiversData, setReceiversData] = useState({
    tableData: [],
    dataCount: 0,
  })
  const [employeesData, setEmployeesData] = useState({
    tableData: [],
    dataCount: 0,
  })
  const [paginatedRequestDataSender, setPaginatedRequestDataSender] = useState({
    search: '',
    pagination: {
      current: 1,
      pageSize: 10,
    },
    sorter: {
      field: 'firstName',
      order: 'ascend',
    },
  })
  const [paginatedRequestDataReceiver, setPaginatedRequestDataReceiver] = useState({
    search: '',
    pagination: {
      current: 1,
      pageSize: 10,
    },
    sorter: {
      field: 'firstName',
      order: 'ascend',
    },
  })
  const [paginatedRequestDataEmployee, setPaginatedRequestDataEmployee] = useState({
    search: '',
    pagination: {
      current: 1,
      pageSize: 10,
    },
    sorter: {
      field: 'createdDate',
      order: 'ascend',
    },
  })

  const fetchSendersList = useCallback(() => {
    client
      .post(`/api/admin/senders/paginated`, { ...paginatedRequestDataSender })
      .then(async (res) => {
        setSendersData({
          tableData: res.data.data,
          dataCount: res.data.total,
        })
      })
      .catch((err) => console.error({ err }))
      .then(() => setTableLoading(false))
  }, [paginatedRequestDataSender])

  const fetchReceiversList = useCallback(() => {
    client
      .post(`/api/admin/receivers/paginated`, {
        ...paginatedRequestDataReceiver,
      })
      .then(async (res) => {
        setReceiversData({
          tableData: res.data.data,
          dataCount: res.data.total,
        })
      })
      .catch((err) => console.error({ err }))
      .then(() => setTableLoading(false))
  }, [paginatedRequestDataReceiver])

  const fetchEmployeesList = useCallback(() => {
    client
      .post(`/api/admin/employees/paginated`, {
        ...paginatedRequestDataEmployee,
      })
      .then(async (res) => {
        setEmployeesData({
          tableData: res.data.data,
          dataCount: res.data.total,
        })
      })
      .catch((err) => console.error({ err }))
      .then(() => setTableLoading(false))
  }, [paginatedRequestDataEmployee])

  useEffect(() => {
    fetchSendersList()
    fetchReceiversList()
    fetchEmployeesList()
  }, [fetchSendersList, fetchReceiversList, fetchEmployeesList])

  useEffect(() => {
    if (!addUserModalVisible) {
      userDetailsFormRef.resetFields()
      setApiLoading(false)
    }
  }, [addUserModalVisible, userDetailsFormRef])

  useEffect(() => {
    if (activeSubMenu === 'senders') fetchSendersList()
    else if (activeSubMenu === 'receivers') fetchReceiversList()
    else if (activeSubMenu === 'employees') fetchEmployeesList()
  }, [activeSubMenu, fetchSendersList, fetchReceiversList, fetchEmployeesList])

  useEffect(() => {
    if (!addUserModalVisible) setIsEditting(false)
  }, [addUserModalVisible])

  const addUser = (values) => {
    setApiLoading(true)
    let endpoint = ''
    let msg = ''
    let data = { ...values }
    data.phoneNumber = values.phoneNumber ? values.phoneNumber.replace(/\D/g, '').substring(0, 10) : ''
    if (activeSubMenu === 'senders') {
      endpoint = '/api/accounts/senders'
      msg = 'Илгээгчийг'
    } else if (activeSubMenu === 'receivers') {
      endpoint = '/api/accounts/receivers'
      msg = 'Хүлээн авагчийг'
      data.address = values.address ?? 'test'
    } else if (activeSubMenu === 'employees') {
      endpoint = '/api/admin/employees'
      msg = 'Ажилтныг'
      data.roles = [values.roles]
      data.userName = values.userName
    }
    client
      .post(endpoint, data)
      .then(async (res) => {
        notification.success({ message: `${msg} амжилттай бүртгэлээ!` })
        if (activeSubMenu === 'senders') fetchSendersList()
        else if (activeSubMenu === 'receivers') fetchReceiversList()
        else if (activeSubMenu === 'employees') fetchEmployeesList()
        setAddUserModalVisible(false)
      })
      .catch((err) => {
        if (err.response.status === 400) notification.warning({ message: err.response.data })
        console.error({ err })
        setApiLoading(false)
      })
  }

  const editUser = (values) => {
    if (activeSubMenu === 'receivers') {
      const endpoint = `/api/accounts/receivers/${userDetails.id}`
      const data = {
        ...values,
        phoneNumber: values.phoneNumber.replace(/\D/g, '').substring(0, 10),
      }
      client
        .put(endpoint, data)
        .then(async (res) => {
          setAddUserModalVisible(false)
          fetchReceiversList()
          notification.success({
            message: 'Хүлээн авагчийн мэдээллийг амжилттай солилоо!',
          })
        })
        .catch((err) => console.error({ err }))
        .then(() => setIsEditting(false))
    } else {
      const data = {
        ...values,
        phoneNumber: values.phoneNumber.replace(/\D/g, '').substring(0, 10),
        roles: [values.roles],
        userName: values.userName,
      }
      client
        .put(`/api/admin/employees/${userDetails.id}`, data)
        .then(async (res) => {
          setAddUserModalVisible(false)
          setIsEditting(false)
          fetchEmployeesList()
          notification.success({
            message: 'Ажилтны мэдээллийг амжилттай солилоо!',
          })
        })
        .catch((err) => {
          if (err.response.status === 400) notification.warning({ message: err.response.data })
          console.error({ err })
          setApiLoading(false)
        })
    }
  }

  const changePinSubmit = () => {
    if (changeUserId)
      changePinFormRef
        .validateFields()
        .then(async (values) => {
          client
            .put(`/api/admin/accounts/${changeUserId}/resetpincode`, values)
            .then(async (res) => {
              notification.success({
                message: 'Пин код амжилттай солигдлоо!',
              })
              changePinFormRef.resetFields()
              setChangePinModalVisible(false)
              setChangeUserId(null)
            })
            .catch((err) => console.error({ err }))
        })
        .catch((info) => {
          console.error('Validate Failed:', info)
        })
  }

  const formatPhone = (number) => {
    if (number) {
      const cleaned = number.replace(/\D/g, '').substring(0, 10)
      const zip = cleaned.substring(0, 3)
      const middle = cleaned.substring(3, 6)
      const last = cleaned.substring(6, 10)
      const first4 = cleaned.substring(0, 4)
      const last4 = cleaned.substring(4, 8)
      let ret = ''
      if (number.length === 10) {
        if (cleaned.length > 6) ret = `(${zip}) ${middle}-${last}`
        else if (cleaned.length > 3) ret = `(${zip}) ${middle}`
        else if (cleaned.length > 0) ret = `(${zip}`
      } else {
        if (cleaned.length > 4) ret = `${first4} ${last4}`
        else if (cleaned.length > 0) ret = `${first4}`
      }
      return ret
    }
    return ''
  }

  const fetchReceiverDetails = (receiverId) => {
    const endpoint = `/api/accounts/receivers/${receiverId}`
    client
      .get(endpoint)
      .then(async (res) => {
        await setUserDetails(res.data)
        setAddUserModalVisible(true)
        userDetailsFormRef.setFieldsValue({
          ...res.data,
          phoneNumber: formatPhone(res.data.phoneNumber),
        })
      })
      .catch((err) => console.error({ err }))
  }

  const fetchEmployeeDetails = async (employeeInfo) => {
    await setUserDetails(employeeInfo)
    userDetailsFormRef.setFieldsValue({
      ...employeeInfo,
      phoneNumber: formatPhone(employeeInfo.phoneNumber),
      roles: employeeInfo.roles?.[0],
    })
  }

  const sendersColumns = [
    {
      title: 'Ажилтны нэр',
      className: 'text-center',
      render: (record) => `${record.firstName} ${record.lastName}`,
    },
    {
      title: 'Утас',
      className: 'text-center',
      dataIndex: 'phoneNumber',
      render: (phone) => {
        return <div className="text-nowrap"> {formatPhone(phone)}</div>
      },
    },
    {
      title: 'Email',
      className: 'text-center',
      dataIndex: 'email',
    },
    {
      title: activeSubMenu === 'employees' ? 'Эрх' : 'Хаяг',
      className: 'text-center',
      width: '500px',
      render: (record) =>
        activeSubMenu === 'employees'
          ? record.roles?.map((el, idx) => el + `${idx !== record.roles.length - 1 ? ', ' : ''}`)
          : record.province
          ? `${record.address ? `${record.address}` : ''}`
          : record.address,
    },
    {
      title: 'Үйлдэл',
      className: 'text-center',
      width: '130px',
      render: (record) => (
        <>
          <div className="d-flex flex-nowrap justify-content-center">
            <Button
              className="btn-sb btn-sb-primary"
              onClick={async () => {
                await setIsEditting(true)
                if (activeSubMenu === 'senders') navigate(`/admin/users/sender/${record.id}`)
                else if (activeSubMenu === 'receivers') {
                  fetchReceiverDetails(record.id)
                } else if (activeSubMenu === 'employees') {
                  fetchEmployeeDetails(record)
                  setAddUserModalVisible(true)
                }
              }}
            >
              <FontAwesomeIcon icon={faEye} size="sm" className="mx-2" />
            </Button>
            {activeSubMenu === 'employees' ? (
              <Button
                className="btn-sb btn-sb-primary ms-2"
                onClick={() => {
                  setChangePinModalVisible(true)
                  setChangeUserId(record.id)
                }}
              >
                <FontAwesomeIcon icon={faUserLock} size="sm" className="mx-2" />
              </Button>
            ) : (
              ''
            )}
          </div>
        </>
      ),
    },
  ]

  const receiversColumns = [
    {
      title: 'Ажилтны нэр',
      className: 'text-center',
      render: (record) => `${record.firstName} ${record.lastName}`,
    },
    {
      title: 'Утас',
      className: 'text-center',
      dataIndex: 'phoneNumber',
      render: (phone) => {
        return <div className="text-nowrap"> {formatPhone(phone)}</div>
      },
    },
    {
      title: 'Email',
      className: 'text-center',
      dataIndex: 'email',
    },
    {
      title: activeSubMenu === 'employees' ? 'Эрх' : 'Хаяг',
      className: 'text-center',
      width: '500px',
      render: (record) =>
        activeSubMenu === 'employees'
          ? record.roles?.map((el, idx) => el + `${idx !== record.roles.length - 1 ? ', ' : ''}`)
          : record.province
          ? `${record.address ? `${record.address}, ` : ''}${record.subdistrict}, ${record.district}, ${record.province}`
          : record.address,
    },
    {
      title: 'Үйлдэл',
      className: 'text-center',
      width: '130px',
      render: (record) => (
        <>
          <div className="d-flex flex-nowrap justify-content-center">
            <Button
              className="btn-sb btn-sb-primary"
              onClick={async () => {
                await setIsEditting(true)
                if (activeSubMenu === 'senders') navigate(`/admin/users/sender/${record.id}`)
                else if (activeSubMenu === 'receivers') {
                  fetchReceiverDetails(record.id)
                } else if (activeSubMenu === 'employees') {
                  fetchEmployeeDetails(record)
                  setAddUserModalVisible(true)
                }
              }}
            >
              <FontAwesomeIcon icon={faEye} size="sm" className="mx-2" />
            </Button>
            {activeSubMenu === 'employees' ? (
              <Button
                className="btn-sb btn-sb-primary ms-2"
                onClick={() => {
                  setChangePinModalVisible(true)
                  setChangeUserId(record.id)
                }}
              >
                <FontAwesomeIcon icon={faUserLock} size="sm" className="mx-2" />
              </Button>
            ) : (
              ''
            )}
          </div>
        </>
      ),
    },
  ]

  const employeesColumns = [
    {
      title: 'Ажилтны нэр',
      className: 'text-center',
      render: (record) => `${record.firstName} ${record.lastName}`,
    },
    {
      title: 'Нэвтрэх нэр',
      className: 'text-center',
      dataIndex: 'userName',
    },
    {
      title: 'Утас',
      className: 'text-center',
      dataIndex: 'phoneNumber',
      render: (phone) => formatPhone(phone),
    },
    {
      title: 'Email',
      className: 'text-center',
      dataIndex: 'email',
    },
    {
      title: 'Эрх',
      className: 'text-center',
      render: (record) => record.roles?.map((el, idx) => el + `${idx !== record.roles.length - 1 ? ', ' : ''}`),
    },
    {
      title: 'Принтер',
      className: 'text-center',
      dataIndex: 'printerIP',
    },
    {
      title: 'Үйлдэл',
      className: 'text-center',
      render: (record) => (
        <>
          <div className="d-flex flex-nowrap">
            <Button
              className="btn-sb btn-sb-primary"
              onClick={async () => {
                await setIsEditting(true)
                if (activeSubMenu === 'senders') navigate(`/admin/users/sender/${record.id}`)
                else if (activeSubMenu === 'receivers') {
                  fetchReceiverDetails(record.id)
                } else if (activeSubMenu === 'employees') {
                  fetchEmployeeDetails(record)
                  setAddUserModalVisible(true)
                }
              }}
            >
              <FontAwesomeIcon icon={faEye} size="sm" className="mx-2" />
            </Button>
            {activeSubMenu === 'employees' ? (
              <Button
                className="btn-sb btn-sb-primary ms-2"
                onClick={() => {
                  setChangePinModalVisible(true)
                  setChangeUserId(record.id)
                }}
              >
                <FontAwesomeIcon icon={faUserLock} size="sm" className="mx-2" />
              </Button>
            ) : (
              ''
            )}
          </div>
        </>
      ),
      width: '10%',
    },
  ]
  return (
    <>
      <Helmet title="Хэрэглэгч" defer={false} />
      <Row gutter={[8, 8]} className="mt-4">
        <Col flex="auto" style={tableTitleStyle}>
          <span>Хэрэглэгчийн жагсаалт</span>
        </Col>
        <Col flex="250px">
          <Search
            className="sb-search sb-shadow"
            placeholder="Хайх"
            onSearch={(value) => {
              setTableLoading(true)
              if (activeSubMenu === 'senders')
                setPaginatedRequestDataSender({
                  ...paginatedRequestDataSender,
                  search: value,
                  pagination: {
                    ...paginatedRequestDataSender.pagination,
                    current: 1,
                  },
                })
              else if (activeSubMenu === 'receivers')
                setPaginatedRequestDataReceiver({
                  ...paginatedRequestDataReceiver,
                  search: value,
                  pagination: {
                    ...paginatedRequestDataReceiver.pagination,
                    current: 1,
                  },
                })
              else if (activeSubMenu === 'employees')
                setPaginatedRequestDataEmployee({
                  ...paginatedRequestDataEmployee,
                  search: value,
                  pagination: {
                    ...paginatedRequestDataEmployee.pagination,
                    current: 1,
                  },
                })
            }}
          />
        </Col>
        {activeSubMenu === 'employees' ? (
          <Col flex="250px">
            <Button className="ant-btn btn-sb btn-sb-primary sb-shadow w-100" onClick={() => setAddUserModalVisible(true)}>
              Ажилтан бүртгэх
            </Button>
          </Col>
        ) : (
          ''
        )}
      </Row>
      <Row gutter={[8, 8]} className="mt-3">
        {subMenus.map((subMenu) => {
          return (
            <Col className="me-4" key={subMenu.dataIndex}>
              <Button
                type="link"
                className="px-0"
                onClick={async () => await setActiveSubMenu(subMenu.dataIndex)}
                style={{
                  fontSize: 18,
                  fontWeight: 500,
                  color: activeSubMenu === subMenu.dataIndex ? '#11427F' : '#333333',
                  borderBottom: activeSubMenu === subMenu.dataIndex ? '1px solid #11427F' : '',
                }}
              >
                {subMenu.dataIndex === 'senders'
                  ? `${subMenu.name} (${sendersData.dataCount ?? 0})`
                  : subMenu.dataIndex === 'receivers'
                  ? `${subMenu.name} (${receiversData.dataCount ?? 0})`
                  : subMenu.dataIndex === 'employees'
                  ? `${subMenu.name} (${employeesData.dataCount ?? 0})`
                  : ''}
              </Button>
            </Col>
          )
        })}
      </Row>
      <Row gutter={[8, 8]} className="mt-2">
        <Table
          className="sb-table w-100 mt-3"
          dataSource={
            activeSubMenu === 'senders' ? sendersData.tableData : activeSubMenu === 'receivers' ? receiversData.tableData : employeesData.tableData
          }
          columns={activeSubMenu === 'employees' ? employeesColumns : activeSubMenu === 'senders' ? sendersColumns : receiversColumns}
          rowKey={(record) => record.id}
          locale={{ emptyText: 'Мэдээлэл байхгүй байна' }}
          loading={tableLoading}
          pagination={
            activeSubMenu === 'senders'
              ? {
                  total: sendersData.dataCount,
                  current: paginatedRequestDataSender.pagination.current,
                  pageSize: paginatedRequestDataSender.pagination.pageSize,
                  onChange: async (page, pageSize) => {
                    setTableLoading(true)
                    setPaginatedRequestDataSender({
                      ...paginatedRequestDataSender,
                      pagination: {
                        current: page,
                        pageSize,
                      },
                    })
                  },
                }
              : activeSubMenu === 'receivers'
              ? {
                  total: receiversData.dataCount,
                  current: paginatedRequestDataReceiver.pagination.current,
                  pageSize: paginatedRequestDataReceiver.pagination.pageSize,
                  onChange: async (page, pageSize) => {
                    setTableLoading(true)
                    setPaginatedRequestDataReceiver({
                      ...paginatedRequestDataReceiver,
                      pagination: {
                        current: page,
                        pageSize,
                      },
                    })
                  },
                }
              : {
                  total: employeesData.dataCount,
                  current: paginatedRequestDataEmployee.pagination.current,
                  pageSize: paginatedRequestDataEmployee.pagination.pageSize,
                  onChange: async (page, pageSize) => {
                    setTableLoading(true)
                    setPaginatedRequestDataEmployee({
                      ...paginatedRequestDataEmployee,
                      pagination: {
                        current: page,
                        pageSize,
                      },
                    })
                  },
                }
          }
        />
      </Row>
      {addUserModalVisible && (
        <AddUserModal
          modalVisible={addUserModalVisible}
          isReceiver={activeSubMenu === 'receivers'}
          closeModal={() => {
            setAddUserModalVisible(false)
            setUserDetails(null)
          }}
          formRef={userDetailsFormRef}
          modalTitle={
            activeSubMenu === 'senders' ? 'Илгээгчийн мэдээлэл' : activeSubMenu === 'receivers' ? 'Хүлээн авагчийн мэдээлэл' : 'Ажилтны мэдээлэл'
          }
          showUSAAddress={activeSubMenu === 'senders' || activeSubMenu === 'employees'}
          showMGLAddress={activeSubMenu === 'receivers'}
          showRole={activeSubMenu === 'employees'}
          isEditting={isEditting}
          userDetails={userDetails}
          onSubmit={isEditting ? editUser : addUser}
          apiLoading={apiLoading}
        />
      )}
      <Modal
        open={changePinModalVisible}
        onCancel={() => {
          setChangePinModalVisible(false)
          changePinFormRef.resetFields()
        }}
        className="sb-modal"
        width={400}
        destroyOnClose
        centered
        title="Хэрэглэгчийн пин код солих"
        footer={[
          <Row justify="center" key="change-pin">
            <Col span={16}>
              <Button
                className="btn-sb btn-sb-primary w-100"
                onClick={() => {
                  changePinSubmit()
                }}
              >
                Солих
              </Button>
            </Col>
          </Row>,
        ]}
      >
        <Form name="change-pin" form={changePinFormRef}>
          <Col span={24}>
            <Form.Item
              name="password"
              label="Шинэ пин код"
              rules={[
                {
                  required: true,
                  message: 'Пин код оруулна уу!',
                },
                {
                  len: 6,
                  message: 'Пин код 6-оронтой байх шаардлагатай',
                },
              ]}
            >
              <Input placeholder="Пин код" type="number" className="sb-input sb-shadow" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="confirmPassword"
              label="Шинэ пин код дахин оруулах"
              rules={[
                {
                  required: true,
                  message: 'Пин код оруулна уу!',
                },
                {
                  len: 6,
                  message: 'Пин код 6-оронтой байх шаардлагатай',
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve()
                    }
                    return Promise.reject('Пин код адилхан байх хэрэгтэй!')
                  },
                }),
              ]}
            >
              <Input placeholder="Пин код" type="number" className="sb-input sb-shadow" />
            </Form.Item>
          </Col>
        </Form>
      </Modal>
    </>
  )
}

export default Users
